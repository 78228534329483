import { createRouter, createWebHistory } from "vue-router";
import SMSTemplate from "../views/Sms/SendTemplate.vue";
import Login from "@/views/SignIn.vue";
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
    meta: {
      auth: false,
      roles: []
    },
  },
  {
    path: "/sms-templates",
    name: "sms-templates",
    meta: {
      auth: true,
      roles: ['agent']
    },
    component: SMSTemplate,
  },

  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
      roles: []
    },
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach(authGuard);
export default router;
