<template>
  <div class="card">
    <div class="card-header">
      <h5 class="mb-0">Enviar SMS usando plantillas</h5>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col col-lg-8 col-md-8 col-sm-12">
          <label>Seleccione una plantilla</label>
          <select class="form-control" @change="selectTemplate($event.target.value)">
            <option value="">Escoja una opcion</option>
            <option :value="item._id" v-for="item in arrayTemplates" :key="item.id">{{item.title}}</option>
          </select>
          <hr>
          <div v-if="template_selected !== '' && inputFields.length > 0">
            <label>Plantilla seleccionada:</label>
            <p disabled v-html="template_selected.description"></p>
            <label>Número de telefono</label>
            <input class="form-control mt-1" placeholder="Enter phone number" v-model="sms.to">
            <label class="mt-2">Campos requeridos: </label>
            <div class="d-flex flex-wrap">
              <div v-for="(field, index) in inputFields" :key="index" class="mb-2 mt-1 col col-lg-6 col-md-6 col-sm-12">
                <label>{{ getFieldLabel(field.variable) }}</label>
                <input @keyup="fillTemplate" class="form-control" v-model="field.value" :class="{ 'invalid': field.value === '' }">
                <span v-if="field.value === ''" class="error-message">Este campo es requerido</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-lg-4 col-md-4 col-sm-12">
          <h6>Vista previa del mensaje</h6>
          <br>
          <label>Numero de telefono</label>
          <p>{{sms.to}}</p>
          <label>Mensaje: </label>
          <div v-html="filledTemplate"></div>
          <button class="btn btn-success w-100 mt-3" :disabled="disabled_button"  @click="sendSms">Enviar SMS</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {get, post} from "@/utils/httpRequest";
import {decrypt} from "@/utils/encrypt";

export default {
  data: () => ( {
    pagination: {
      searchTimer: null,
      page: 1,
      pages: 0,
      limit: 20,
      search: '',
      totalRows: 0
    },
    template_selected: '',
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      language: 'es',
      rows: 10,
      height: 500,
      toolbar: {
        items: [
          'bold',
          'italic',
          'link',
          'undo',
          'redo'
        ]
      },
    },
    arrayTemplates: [],
    template: {
      permission: 'all',
      title: '',
      description: '',
    },
    inputFields: [],
    filledTemplate: '',
    sms: {
      user: '',
      to: '',
      message: ''
    },
    disabled_button: false
  }),
  computed: {
    areFieldsEmpty() {
      return this.inputFields.some(field => field.value === '');
    }
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    async saveTemplate() {
      if(!this.$userCan('administrator')){
        const store = localStorage.getItem('payment-admin-type');
        this.template.permission = decrypt(store);
      }
      if (this.template.title !== '' && this.template.description !== '' && this.containsPlaceholder(this.template.description)) {
        await post("sms/create-template", {...this.template});
        this.template = {
          permission: 'all',
          title: '',
          description: '',
        }
        await this.getTemplates();
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
      }else{
        this.$swal({
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
          html: 'Please fill all fields or the message must contain a ${variable_name} variable',
        })
      }
    },
    async deleteTemplate(id) {
      await this.$swal({
        showCancelButton: true,
        confirmButtonText: 'YES',
        cancelButtonText: `NO`,
        html: 'Are you sure?'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await post("sms/delete-template", {_id: id});
          await this.getTemplates();
        }
      })
    },
    async getTemplates() {
      const {data} =  await get(`sms/get-templates?page=${this.pagination.page}&limit=${this.pagination.limit}&search=${this.pagination.search}`);
      this.arrayTemplates = data.docs
      this.pagination.totalRows = data.count
      this.pagination.page = data.page
      this.pagination.pages = data.pages
    },
    async selectTemplate(value) {
      this.template_selected = ''
      this.inputFields = []
      this.filledTemplate = ''
      this.sms = {
        user: '',
        to: '',
        message: ''
      }
      if (value !== '') {
        this.template_selected = this.arrayTemplates.find(item => item._id === value);
        this.parseTemplate()
      }
    },
    parseTemplate() {
      const regex = /\${(.*?)}/g;
      const matches = this.template_selected.description.match(regex);
      if (matches) {
        this.inputFields = matches.map(match => ({
          variable: match,
          value: ''
        }));
      }
    },
    getFieldLabel(variable) {
      return variable.replace(/\${|}/g, '');
    },
    async fillTemplate() {
      if(this.validateAllEmptyValues(this.inputFields)){
        this.filledTemplate = ''
        return
      }
      if (!this.validateNonEmptyValues(this.inputFields)) {
        return;
      }

      let filledTemplate = this.template_selected.description;
      for (const field of this.inputFields) {
        filledTemplate = filledTemplate.replace(field.variable, field.value);
      }
      this.filledTemplate = filledTemplate;
    },

    validateNonEmptyValues(array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value !== '') {
          return true; // At least one non-empty value found
        }
      }
      return false; // No non-empty value found
    },
    validateAllEmptyValues(array) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value !== '') {
          return false; // Al menos un valor no está vacío
        }
      }
      return true; // Todos los valores están vacíos
    },

    containsPlaceholder(text) {
      const regex = /\${.*?}/;
      return regex.test(text);
    },
    async sendSms(){
      this.disabled_button = true
      await this.fillTemplate()
      this.sms.message = this.filledTemplate
      if(this.sms.to !== '' && this.sms.message !== ''){
        const {data} = await post('sms/send-sms',{to: this.sms.to, message: this.sms.message})
        await post('sms/create-sms',{...this.sms, response: data})
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }else{
        await this.$swal({
          html: 'Please fill all fields or the message must contain a ${} variable',
          icon: 'error',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        })
        this.disabled_button = false
      }
    },
    async sendSmsOld(){
      this.disabled_button = true
      if(this.sms.to !== '' && this.sms.message !== ''){
        const {data} = await post('sms/send-sms',{to: this.sms.to, message: this.sms.message})
        await post('sms/create-sms',{...this.sms, response: data})
        await this.$swal({
          html: 'Process successfully',
          icon: 'success',
          timer: 2000,
          showCancelButton: false,
          showConfirmButton: false,
        }).then(() => {
          window.location.reload();
        });
      }else{
        this.disabled_button = false
      }
    },
    reloadPaginate(page){
      this.pagination.page = page
      this.getSms()
    },
    copyToClipboard() {
      const content = "${}";
      const input = document.createElement("input");
      input.value = content;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
  }
};

</script>


<style scoped>
.invalid {
  border: 1px solid red;
}
.error-message {
  color: red;
}
</style>
