<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item" >
       <div class="text-center">
         <p v-if="user" class="text-uppercase text-dark font-weight-bold mb-0">{{user.name}}</p>
         <p v-if="user" class="text-lowercase text-sm">{{user.email}}</p>
       </div>
      </li>

      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="Users" :to="{ name: 'users' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <div class=" sidenav-footer">
    <a
      class="btn bg-gradient-success mt-4 w-100"
      @click="logout"
      type="button"
      >Cerrar session</a
    >
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import Office from "../../components/Icon/Office.vue";
import {decrypt} from "@/utils/encrypt";
import routerList from "@/router/index"
import {get} from "@/utils/httpRequest";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data : () => ({
    title: "Pagos",
    controls: "",
    isActive: "active",
    arrayOptions: [
        {key:'dashboard', name: ''},
      'users','transactions','leads', 'clients'],
    arrayMenu: [],
    user_id: null,
    user: null
  }),
  components: {
    SidenavCollapse,
    Office,
  },
  mounted() {
    this.getMenu()
    this.user_id = localStorage.getItem('sms-agent-uuid')
    setTimeout(()=>{
      this.getProfile()
    },500)
  },
  methods: {
    getMenu(){
      const storage = localStorage.getItem('sms-agent-type')
      setTimeout(()=>{
        const rol = decrypt(storage)
        const router = routerList.options.routes;
        this.arrayOptions.forEach(el => {
          router.forEach(item=> {
            if(item.name === el){
              if(item.meta.roles.includes(rol)){
                this.arrayMenu.push(el)
              }
            }
          })
        })
      },500)
    },
    async getProfile(){
      const {data} = await get(`users/find-one?id=${this.user_id}`, null)
      data.password = ''
      this.user = data
    },
    logout() {
      localStorage.clear()
      this.$router.push('/')
    }
  },
};
</script>
